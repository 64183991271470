
body {
    .prize-winned-page {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 30px);
        margin: 0 -15px;
        padding: 0;
        z-index: 2;
        height: 100%;
        overflow: auto;

        &.confeti-class{
            background-image: url('../assets/images/confeti.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &.error-class {
            display: flex;
            align-content: flex-end;
            align-items: flex-end;
            .play-again-button {
                opacity: 0;
            }
        }

        .play-big-image-1 {
            display: block;
            position: fixed;
            right: -15px;
            top: 0;
            width: 70%;
        }
    }
}
