
body {
    .forms-page {
        fieldset {
            border: 0;
            display: block;
            padding: 10px;
            margin-top: 20px;

            label,
            input {
                display: block;
            }

            label {
                width: calc(100% - 20px);
                padding-left: 10px;
                text-align: center;
                margin-bottom: 8px;
                color: #fff;
                font-size: .74em;
                line-height: 1;
                letter-spacing: 0;
            }

            input {
                appearance: none;
                -webkit-appearance: none;
                width: calc(100% - 30px);
                margin-bottom: 15px;
                border: 0;
                border-radius: 25px;
                padding: 5px 15px;
                &:hover,
                &:focus,
                &:active {
                    outline: 0;
                }
            }
        }
        fieldset + small {
            color: #fff;
            font-size: 12px;
            text-align: center;
            display: block;
            margin: 5px 0 20px;
        }

        .form-caja {
            display: none;
        }

        &.amazon {
            fieldset {
                padding-bottom: 0;
            }
            .form-caja {
                display: block;
                margin: 0 auto 15px;
                width: 90%;
            }
        }
    }
}
