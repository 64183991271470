
body {
    .postal-section {
        height: 100%;
        overflow: visible;
        position: relative;

        &.tip {
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
        }

        .close-top-button {
            z-index: 99;
            top: 12px;
            right: 15px;
            width: 47px;
            height: 47px;
        }

        h1.font-jellee {
            color: #fff;
            letter-spacing: 0 !important;
            font-size: 2.5em;
            margin: 40px 0 0;
            line-height: 1;
            text-align: center;
            font-weight: normal;
            display: block;
            text-shadow: -4px -2px 0 #213b85;
            transform: rotate(-8deg) skew(-8deg, 0);

            span {
                margin-top: 5px;
                line-height: 1;
                font-size: .5em;
                display: block;
            }
            @media screen and (min-height: 667px) {
                margin: 50px 0 0;
                font-size: 3em;
            }
            @media screen and (min-height: 780px) {
                margin: 50px 0 0;
                font-size: 3.5em;
            }
        }

        .header-tip-image {
            width: 80%;
        }

        .tipImage{
            width: 75%;
            display: block;
            margin: 15px auto 0;
        }

        p.font-jellee {
            color: #fff;
            letter-spacing: 0 !important;
            font-size: .8em;
            margin: 30px 0 0;
            line-height: 1;
            text-align: center;
            font-weight: normal;
            display: block;
            text-shadow: -2px -1px 0 #213b85;
            transform: rotate(-6deg) skew(-6deg, 0);

            span {
                margin-top: 5px;
                line-height: 1;
                font-size: 1.4em;
            }
            @media screen and (min-height: 640px) {
                margin-top: 35px;
                font-size: 0.8em;
                span {
                    font-size: 1.4em;
                }
            }
            @media screen and (min-height: 736px) {
                margin-top: 50px;
            }
            @media screen and (min-height: 780px) {
                span {
                    font-size: 1.4em;
                }
            }
        }

        .envelop-big-cinema,
        .postal-imagecard-cinema {
            position: absolute;
        }

        .envelop-big-cinema {
            bottom: -5%;
            right: -16%;
            width: 98%;
        }

        .postal-imagecard-cinema {
            transform: rotate(9deg);
            z-index: 3;
            width: 85%;
            left: 8%;
            top: 19vh;
        }

        .share-button {
            display: block;
            margin: 0 auto;
            width: 47px;
            height: 47px;
            position: fixed;
            right: 15px;
            bottom: 15px;
        }

        .footerImage{
            position: fixed;
            left: 50%;
            bottom: 20px;
            width: 240px;
            transform: translate(-50%);
            @media screen and (min-height: 667px) {
                width: 240px;
            }
            @media screen and (min-height: 780px) {
                width: 280px;
            }
        }
    }
}
