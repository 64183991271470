
body {
    .recipe-section {
        overflow: hidden;
        margin: 0;
        padding: 0 20px;
        background-position: center bottom;
        background-size: 86%;
        background-repeat: no-repeat;

        .close-top-button {
            z-index: 99;
            top: 12px;
            right: 15px;
            width: 40px;
            height: 40px;
        }

        h1 {
            font-family: 'jellee';
            color: #fff;
            letter-spacing: 0 !important;
            font-size: 2.2em;
            margin: 50px 0 0;
            line-height: 1;
            text-align: center;
            font-weight: normal;
            display: block;
            text-shadow: -5px -2px 0 #213b85;
            transform: rotate(-8deg) skew(-8deg, 0);
            &.comillas{
                &:before{
                    margin-right: 5px;
                }
            }

            span {
                margin-top: 5px;
                line-height: 1;
                font-size: .45em;
                display: block;
            }
        }

        .receta-imagecard-cinema {
            width: 83%;
            display: block;
            margin: 25px auto 0;
        }

        .recipe-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0 0 0;
            width: 100%;
            a {
                display: block;
            }

            a {
                color: #fff;
                text-decoration: none;
                margin: 0;
                display: block;
                border: 2px solid #fff;
                border-radius: 25px;
                padding: 15px;
                white-space: nowrap;
                font-size: .75em;
                letter-spacing: 0;
                font-family: 'jellee';
                font-weight: normal;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        .free-prize-logo {
            display: block;
            width: 90px;
            height: auto;
            position: fixed;
            bottom: 10px;
            left: calc(50% - 45px)
        }

        button {
            height: 38px;
            width: 141px;
            &.share-button {
                display: block;
                width: 47px;
                height: 47px;
                position: fixed;
                right: 15px;
                bottom: 15px;
            }
        }
    }
}