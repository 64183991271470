
body {
    .PrizeKitCinema {
        overflow: hidden;
        padding: 28px 15px;

        &.cine {
            h1:not(.last-error-message) {
                font-size: 1.6em;
                transform: rotate(-8deg) skew(-8deg, 0);
                @media screen and (min-height: 660px) {
                    padding-top: 20px;
                }
                &.prize-wined-main-title {
                    text-align: center;
                    font-size: 2.3em;
                    line-height: 1;
                    letter-spacing: 0;
                    span {
                        display: block;
                        font-size: 0.6em;
                    }
                }
            }
        }
        &.kit {
            h1:not(.last-error-message) {
                font-size: 2.9em;
                transform: rotate(-8deg) skew(-8deg, 0);
                &.prize-wined-prev-main-title-kit {
                    font-size: 2em;
                }
                &.prize-wined-main-title {
                    font-size: 1.8em;
                    text-align: center;
                    margin-bottom: 25px;
                }
            }
            .prize-wined-main-container{
                text-align: center;
            }
        }
        &.Amazon.step-1 {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            height: calc(100% - 100px);
        }
        &.Amazon.step-4 {
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            min-height: calc(100% - 30px);
            .forms-page fieldset {
                margin-top: 0;
                padding: 0 !important;
            }
            button.send-button {
                margin-bottom: 0;
            }
        }

        button {
            &:active,
            &:focus,
            &:hover {
                outline: 0;
            }
            &.send-button{
                margin-bottom: 80px;
                margin-top: 10px;
            }
        }

        h1 {
            margin: 0 auto 0;
            color: #fff;
            font-family: 'jellee';
            font-weight: normal;
            text-shadow: -5px -2px 0 #213b85;
            font-size: 1.8em;
            line-height: 0.95;
            @media screen and (min-height: 667px) {
                font-size: 2.3em;
                margin: 10px auto 0;
            }

            &.last-title {
                font-size: 1.27em;
                span {
                    font-size: 1.45em;
                }
            }
        }

        .instructionsText {
            margin: 0;
            line-height: 1;
            font-weight: normal;
            font-size: 0.9em;
            color: #fff;
            letter-spacing: 0;
            span{
                font-size: 1.1em;
            }
            @media screen and (min-height: 730px) {
                font-size: 1em;
                span{
                    font-size: 1.2em;
                }
            }
        }

        .kit-prizekit-cinema,
        .tikets-prizekit-cinema {
            display: block;
            margin: 10px auto;
            @media screen and (min-height: 660px) {
                margin: 20px auto;
            }
            @media screen and (min-height: 730px) {
                margin: 30px auto;
            }
        }

        .kit-prizekit-cinema {
            width: 80%;
            &.spoty {
                height: 260px;
                width: auto;
                margin: -5px auto;
                @media screen and (min-height: 730px) {
                    margin: 10px auto;
                    height: 300px;
                }
            }
        }

        .tikets-prizekit-cinema {
            width: 175px;
        }

        .triviaimage-prizekit-cinema {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-size: 1.1em;
            color: #fff;
            font-weight: normal;
            padding: 20px 4px;
        }

        .answer-button {
            display: block;
            color: #fff;
            margin: 20px auto;
            width: 160px;
            padding: 10px 0px;
            border: 2px solid #fff;
            text-align: center;
            border-radius: 25px;
            font-size: 0.9em;
            &.selected {
                background-color: #172d7f;
            }
        }

        .prize-wined-main-description {
            text-align: center;
            color: #fff;
            line-height: 1.1;
            span {
                letter-spacing: 0;
            }
        }

        .cine-prizekit-wined-cinema,
        .kit-prizekit-wined-cinema {
            display: block;
            margin: 20px auto;
        }

        .last-error-message {
            font-size: 1.5em;
            text-align: center;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            font-family: Arial, Helvetica, sans-serif;
            flex-wrap: wrap;
            margin: 0;
            span {
                font-family: 'jellee';
            }
        }

        .error-fill {
            display: block;
            overflow: hidden;
            text-align: center;
            font-size: 12px;
            line-height: 1.4;
            letter-spacing: 0;
            color: #071c41;
            font-weight: bold;
            width: 100%;
        }

        .prize-kit-button {
            display: block;
            margin: 20px auto 0;
            width: 160px;
            height: 46px;
            @media screen and (min-height: 566px) {
                margin-top: 35px;
            }
        }

        .push-to-right {
            width: 100%;
            overflow: hidden;
            padding: 0 0 0 4px;
        }

        .prize-kit-logo {
            display: block;
            width: 90px;
            height: auto;
            margin: 0 auto;
            position: fixed;
            bottom: 10px;
            left: calc(50% - 45px);
        }
        &.step-4 {
            .prize-kit-logo {
                display: none;
            }
        }

        button.loadin-button {
            display: flex;
            position: relative;
            color: #000;
            justify-content: center;
            align-items: center;
            &:before {
                display: block;
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
            }

            & > svg {
                -webkit-animation: fa-spin 2s infinite linear;
                animation: fa-spin 2s infinite linear;
                position: absolute;
            }
        }

        &.premio-ganado {
            h1.last-title {
                margin-top: 80px;
            }

            .kit-prizekit-cinema {
                width: 92%;
                &.spoty {
                    height: 260px;
                    width: auto;
                    margin: 0 auto;
                    @media screen and (min-height: 667px) {
                        margin: 15px auto;
                        height: 320px;
                    }
                }
            }
        }

        &.wrong-answer {
            h1 {
                margin-top: 100px;
                text-align: center;
            }
        }
    }


    .marca-agua {
        position: fixed;
        bottom: 10px;
        left: 10px;
        color: rgba(255, 255, 255, .6);
        font-size: .7em;
    }

    &.formulario {
        .PrizeKitCinema .prize-kit-logo {
            position: absolute;
        }
    }
}
