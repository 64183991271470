body {
    .welcome-page {
        margin: 0;
        height: 100%;
        width: 100%;
        position: relative;

        .main-title {
            display: block;
            margin: 0;
            padding: 15px 0 0 0;
            text-align: center;
            font-size: .85em;
            color: #fff;
            &.closed-dinamic {
                padding-top: 120px;
                margin-bottom: 120px;
            }
            @media (min-width: 360px) {
                font-size: .95em;
            }
            @media (min-width: 370px) {
                font-size: .98em;
                padding-top: 20px;
            }
            @media (min-height: 760px) {
                font-size: 1em;
                padding-top: 30px;
            }
        }

        .img-text {
            display: block;
            margin: 40px auto 0;
            width: 82%;
            @media (max-height: 500px) {
                margin-top: 20px;
            }
            @media (min-height: 730px) {
                margin-top: 55px;
            }
            @media (min-height: 760px) {
                margin-top: 80px;
            }
        }

        .intro-receta-png {
            display: block;
            position: absolute;
            margin-top: 20px;
            left: 50%;
            // left: -15px;
            // bottom: 0;
            max-width: 10000px;
            // height: 300px;
            height: 290px;
            width: auto;
            transform: translate(-50%, 0);
            @media (max-height: 500px) {
                // height: 225px;
                height: 215px;
            }
            @media (min-height: 650px) {
                // height: 310px;
                height: 300px;
            }
            @media (min-height: 730px) {
                height: 324px;
                // height: 334px;
                // height: 340px;
            }
            // @media (min-height: 790px) {
            //     height: 370px;
            // }
        }
    }
}
