%logo-la-moderna {
    background-image: url('../assets/images/logoLmB.png');
}
%back-texture {
    background-image: url('../assets/images/textura.png');
}
/*
%back-texture-2 {
    background-image: url('../assets/images/textura-2.png');
}
*/
%back-sopas {
    background-image: url('../assets/images/sopas.png');
}
%button-next {
    background-image: url('../assets/images/button-next.png');
}
%button-play {
    background-image: url('../assets/images/button-play.png');
}
%button-play-again {
    background-image: url('../assets/images/button-play-again.png');
}
%button-register {
    background-image: url('../assets/images/button-register.png');
}
%button-send {
    background-image: url('../assets/images/button-send.png');
}
%button-share {
    background-image: url('../assets/images/button-share.png');
}
/* nuevos */
%button-next-new {
    background-image: url('../assets/images/bottom-next-button.png');
}
%button-close-new {
    background-image: url('../assets/images/top-close-button.png');
}
%background-comillas {
    background-image: url('../assets/images/comillas.png');
}
