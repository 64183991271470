body {
    .message-screen-page {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;

        .close-top-button {
            z-index: 99;
            top: 12px;
            right: 0;
            width: 47px;
            height: 47px;
        }

        h1 {
            font-family: 'jellee';
            color: #fff;
            text-shadow: -3px -1px 0 #213b85;
            transform: rotate(-8deg) skew(-8deg, 0);
        }

        .bottom-logo{
            width: 80px!important;
            height: auto;
            position: fixed;
            bottom: 0;
            left: calc(50% - 40px);
            margin: 0 auto!important;
        }
    }
}
