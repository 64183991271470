body {
    .error-404-page {
        @include text-border-5($white);

        h1 {
            font-size: 2.5em;
            margin: 80px 0;
        }
    }
}
