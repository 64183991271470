body {
    .play-page {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.playing-now {
            justify-content: flex-start;
        }

        .play-main-title-h1 {
            color: #fff;
            margin: 85px 0 0 0;
            font-size: 1.7em;
            line-height: 1;
            text-shadow: -4px -1px 0 #213b85;
        }

        .play-big-image-1 {
            display: block;
            width: 90%;
            margin-bottom: 20px;
        }

        .bottom-logo {
            display: block;
            width: 90px;
            height: auto;
            position: fixed;
            bottom: 10px;
            left: calc(50% - 45px)
        }

        .intro-play-text {
            display: block;
            margin: 0;
            line-height: 1.1;
            color: #fff;
            margin-bottom: 20px;
            @media screen and (min-height: 640px) {
                margin-bottom: 30px;
            }
            @media screen and (min-height: 780px) {
                margin-bottom: 50px;
                font-size: 1.2em;
            }
        }

        .loading-game {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -10px;

            svg {
                -webkit-animation: fa-spin 2s infinite linear;
                animation: fa-spin 2s infinite linear;
            }
        }

        .play-action-buttons {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            flex-wrap: wrap;
            margin-bottom: 100px;
            @media screen and (min-height: 710px) {
                margin-bottom: 0;
            }
            button {
                &.play-button-text {
                    font-family: 'asap';
                    font-weight: normal;
                    color: $white;
                    border-bottom: 1px solid #fff;
                    margin-bottom: 20px;
                    font-size: .7em;
                    @media screen and (min-height: 667px) {
                        margin-bottom: 25px;
                    }
                    @media screen and (min-height: 780px) {
                        margin-bottom: 30px;
                        font-size: .9em;
                    }
                }

                &.play-button {
                    width: 132px;
                    margin-bottom: 15px;
                    @media screen and (min-height: 667px) {
                        width: 168px;
                        margin-bottom: 30px;
                    }
                }

                &.fb-login {
                    width: 250px;
                    height: 47px;
                    background-color: #4267b2;
                    border-radius: 40px;
                    margin-bottom: 8px;
                    border: 1px solid #fff;

                    span {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: .8em;
                        svg {
                            margin-right: 10px;
                        }
                        i {
                            font-family: 'jellee';
                            font-weight: normal;
                            font-style: normal;
                            font-size: .8em;
                            line-height: 1;
                        }
                    }

                    @media screen and (max-width: 374px) {
                        width: 245px;
                        height: 38px;
                    }
                }

                &.loadin-button {
                    display: flex;
                    position: relative;
                    color: #000;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        display: block;
                        width: 100%;
                        height: 100%;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    & > svg {
                        -webkit-animation: fa-spin 2s infinite linear;
                        animation: fa-spin 2s infinite linear;
                        position: absolute;
                    }
                }
            }
        }

        .main-pinata {
            width: 80%;
            height: calc(100% - 100px);
            left: 10%;
            top: 0;
            bottom: auto;
            z-index: -1;
            display: none;
            background-image: url('../assets/images/caja.png');
            background-position: 0 bottom;
            background-repeat: no-repeat;
            background-size: contain;
            &.game-started {
                display: block;
            }
            .fiery {
                z-index: 0;
                position: absolute;
                color: #fff;
                animation: fierytop 1s ease-in;
            }
        }

        .pinata-xplosion {
            opacity: 0;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin: 0;
            padding: 0;
            border: 0;
            z-index: -1;
            &.winned{
                opacity: 1;
                z-index: 10;

                img {
                    position: absolute;
                    width: 50%;
                    height: auto;
                    max-width: 800%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    animation-timing-function: ease-in-out;
                    animation-direction: forwards;
                    animation-fill-mode: forwards;
                    animation-duration: .5s;
                    animation-name: grow-caja;
                }
            }

        }
    }
}

@keyframes fierytop {
    from { color: #fff; }
    to { color: #fff; left: 90%; bottom: 100%; opacity: 0; } 
}

@keyframes grow-caja {
    100% {
        width: 280%;
    }
}
