@import url(~normalize.css/normalize.css);
@import './colors.scss',
        './files.scss',
        './mixins.scss',
        '../shared/BottomButtons/BottomButtons.scss',
        '../shared/BottomButtonsNew/BottomButtonsNew.scss',
        '../shared/MessageScreen/MessageScreen.scss',
        '../shared/Forms/Forms.scss',
        '../components/404/404.scss',
        '../components/Play/Play.scss',
        '../components/Postal/Postal.scss',
        '../components/PrizeKitCinema/PrizeKitCinema.scss',
        '../components/PrizeWinned/PrizeWinned.scss',
        '../components/Recipe/Recipe.scss',
        '../components/Intro/Intro.scss';

@font-face {
	font-family: 'lamoderna-type';
	src:url('../assets/fonts/american-typewriter-condensed.eot');
	src:url('../assets/fonts/american-typewriter-condensed.woff') format('woff'),
		url('../assets/fonts/american-typewriter-condensed.ttf') format('truetype'),
		url('../assets/fonts/american-typewriter-condensed.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'lamoderna-type-light';
	src:url('../assets/fonts/american-typewriter-light.eot');
  src:url('../assets/fonts/american-typewriter-light.woff') format('woff'),
      url('../assets/fonts/american-typewriter-light.ttf') format('truetype'),
      url('../assets/fonts/american-typewriter-light.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'lamoderna-sep';
	src:url('../assets/fonts/september.eot');
  src:url('../assets/fonts/september.woff') format('woff'),
      url('../assets/fonts/september.ttf') format('truetype'),
      url('../assets/fonts/september.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'asap';
	src:url('../assets/fonts/nuevas/Asap-Regular.eot');
  src:url('../assets/fonts/nuevas/Asap-Regular.woff') format('woff'),
      url('../assets/fonts/nuevas/Asap-Regular.ttf') format('truetype'),
      url('../assets/fonts/nuevas/Asap-Regular.otf') format("opentype"),
      url('../assets/fonts/nuevas/Asap-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'jellee';
	src:url('../assets/fonts/nuevas/Jellee-Roman.eot');
  src:url('../assets/fonts/nuevas/Jellee-Roman.woff') format('woff'),
      url('../assets/fonts/nuevas/Jellee-Roman.ttf') format('truetype'),
      url('../assets/fonts/nuevas/Jellee-Roman.otf') format("opentype"),
      url('../assets/fonts/nuevas/Jellee-Roman.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'nuit';
	src:url('../assets/fonts/nuevas/NUIT.eot');
  src:url('../assets/fonts/nuevas/NUIT.woff') format('woff'),
      url('../assets/fonts/nuevas/NUIT.ttf') format('truetype'),
      url('../assets/fonts/nuevas/NUIT.otf') format("opentype"),
      url('../assets/fonts/nuevas/NUIT.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-size: 16pt;

  div#root {
    overflow: hidden !important;
    position: relative !important;
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
    padding: 0 !important;
    border: 0 !important;
    min-height: 568px !important;

    .font-asap {
      font-family: 'asap';
      font-weight: normal !important;
    }
    .font-jellee {
      font-family: 'jellee';
      font-weight: normal !important;
    }
    .font-nuit {
      font-family: 'nuit';
      font-weight: normal !important;
    }
  }
}

body {
  @extend %back-texture;
  margin: 0;
  padding: 0;
  font-family: 'asap', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: repeat;
  background-position: top center;
  background-size: cover;
  background-color: #124dc3;
  &.texture-2 {
    @extend %back-texture;
  }

  h1.comillas{
    &:before{
      content: '';
      display: inline-block;
      width: 20px;
      height: 15px;
      @extend %background-comillas;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      padding-bottom: 15px;
      @media screen and (min-height: 667px) {
        width: 24px;
        padding-bottom: 30px;
      }
      @media screen and (min-height: 780px) {
        width: 28px;
        padding-bottom: 40px;
      }
    }
  }

  *.font-sep {
    font-weight: normal;
    font-family: 'lamoderna-sep', sans-serif;
  }

  img {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 100%;
    height: auto;
  }

  .section-body {
    display: block;
    height: 100%;
    width: calc(100vw - 30px);
    margin: 0 auto;
    padding: 0;
    max-width: 390px;
    min-height: 550px;
    position: relative;

    @media (orientation: landscape) {
      max-width: 340px;
    }
  }

  .text-center {
    text-align: center;
  }

  button {
    @include appearance(none);
    @include border-radius(0);
    display: inline-block;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  .next-button,
  .play-button,
  .play-again-button,
  .register-button,
  .send-button,
  .share-button {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 180px;
    height: 52px;

    @media screen and (max-width: 374px) {
      width: 140px;
      height: 41px;
    }
  }

  .next-button {
    @extend %button-next;
  }
  .play-button {
    @extend %button-play;
  }
  .play-again-button {
    @extend %button-play-again;
  }
  .register-button {
    @extend %button-register;
  }
  .send-button {
    @extend %button-send;
  }
  .share-button {
    @extend %button-share;
  }
  .square-send-button {
    margin-top: 40px;
    width: 150px;
    height: 35px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 25px;
    font-size: .85em;
    &:before {
      content: 'Enviar';
    }
  }

  .next-button-new {
    background-repeat: no-repeat;
    background-size: contain;
    width: 46px;
    height: 46px;

  }
  .next-button-new {
    @extend %button-next-new;
  }

  .close-top-button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 52px;
    height: 52px;
    @extend %button-close-new;
    background-size: contain;
    background-repeat: no-repeat;
    &.left {
      left: 5px;
      right: auto;
    }
  }

  .main-pinata {
    position: absolute;
    height: auto;
  }

  .skew {
    transform: rotate(-8deg) skew(-8deg, 0);
  }

  .nodesktop,
  .nolandscape {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 2.8em;
    font-weight: bold;
    flex-flow: column;

    span {
      color: #fff;
      display: block;
      padding: 20px;
      text-shadow: -6px -2px 0 #213b85;
    }
    .nodesktop-logo {
      display: block;
      margin: 15px 0 0 0;
      width: 100%;
      height: 90px;
      @extend %logo-la-moderna;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
/*
  @media screen and (max-height: 450px) and (orientation:landscape) {
    #root {
      opacity: 0;
    }
    .nolandscape {
      display: flex;
    }
  }
*/
  @media screen and (min-height: 451px) and (min-width: 500px) {
    #root {
      opacity: 0;
    }
    .nodesktop {
      display: flex;
    }
  }
}
