
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin no-select(){
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin no-drag(){
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
@if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    -ms-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
} @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    -ms-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
}
}

@mixin box-shadow-important($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color !important;
        -moz-box-shadow:inset $top $left $blur $color !important;
        -ms-box-shadow:inset $top $left $blur $color !important;
        box-shadow:inset $top $left $blur $color !important;
    } @else {
        -webkit-box-shadow: $top $left $blur $color !important;
        -moz-box-shadow: $top $left $blur $color !important;
        -ms-box-shadow: $top $left $blur $color !important;
        box-shadow: $top $left $blur $color !important;
    }
}

@mixin no-box-shadow() {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

@mixin text-border-1($color) {
    /*
    -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: $color;

    -webkit-text-shadow: 1px 0 0 $color, -1px 0 0 $color, 0 1px 0 $color, 0 -1px 0 $color;
    -moz-text-shadow: 1px 0 0 $color, -1px 0 0 $color, 0 1px 0 $color, 0 -1px 0 $color;
    -ms-text-shadow: 1px 0 0 $color, -1px 0 0 $color, 0 1px 0 $color, 0 -1px 0 $color;
    text-shadow: 1px 0 0 $color, -1px 0 0 $color, 0 1px 0 $color, 0 -1px 0 $color;
    */
    -webkit-text-shadow:
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color;
    -moz-text-shadow:
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color;
    -ms-text-shadow:
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color;
    text-shadow:
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color,
        0 0 2px $color, 0 0 2px $color, 0 0 2px $color, 0 0 2px $color;
}
@mixin text-border-2($color) {
    /*
    -webkit-text-stroke-width: 2px;
   -webkit-text-stroke-color: $color;

    -webkit-text-shadow: 2px 0 0 $color, -2px 0 0 $color, 0 2px 0 $color, 0 -2px 0 $color, 1px 1px $color, -1px -1px $color, 1px -1px $color, -1px 1px $color;
    -moz-text-shadow: 2px 0 0 $color, -2px 0 0 $color, 0 2px 0 $color, 0 -2px 0 $color, 1px 1px $color, -1px -1px $color, 1px -1px $color, -1px 1px $color;
    -ms-text-shadow: 2px 0 0 $color, -2px 0 0 $color, 0 2px 0 $color, 0 -2px 0 $color, 1px 1px $color, -1px -1px $color, 1px -1px $color, -1px 1px $color;
    text-shadow: 2px 0 0 $color, -2px 0 0 $color, 0 2px 0 $color, 0 -2px 0 $color, 1px 1px $color, -1px -1px $color, 1px -1px $color, -1px 1px $color;
    */
    -webkit-text-shadow:
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color;
    -moz-text-shadow:
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color;
    -ms-text-shadow:
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color;
    text-shadow:
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color,
        0 0 4px $color, 0 0 4px $color, 0 0 4px $color, 0 0 4px $color;
}
@mixin text-border-3($color) {
    /*
    -webkit-text-stroke-width: 3px;
   -webkit-text-stroke-color: $color;

    -webkit-text-shadow: 3px 0 0 $color, -3px 0 0 $color, 0 3px 0 $color, 0 -3px 0 $color, 2px 2px $color, -2px -2px $color, 2px -2px $color, -2px 2px $color;
    -moz-text-shadow: 3px 0 0 $color, -3px 0 0 $color, 0 3px 0 $color, 0 -3px 0 $color, 2px 2px $color, -2px -2px $color, 2px -2px $color, -2px 2px $color;
    -ms-text-shadow: 3px 0 0 $color, -3px 0 0 $color, 0 3px 0 $color, 0 -3px 0 $color, 2px 2px $color, -2px -2px $color, 2px -2px $color, -2px 2px $color;
    text-shadow: 3px 0 0 $color, -3px 0 0 $color, 0 3px 0 $color, 0 -3px 0 $color, 2px 2px $color, -2px -2px $color, 2px -2px $color, -2px 2px $color;
    */
    -webkit-text-shadow:
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color;
    -moz-text-shadow:
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color;
    -ms-text-shadow:
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color;
    text-shadow:
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color,
        0 0 6px $color, 0 0 6px $color, 0 0 6px $color, 0 0 6px $color;
}
@mixin text-border-4($color) {
    /*
    -webkit-text-stroke-width: 4px;
   -webkit-text-stroke-color: $color;

    -webkit-text-shadow: 4px 0 0 $color, -4px 0 0 $color, 0 4px 0 $color, 0 -4px 0 $color, 3px 3px $color, -3px -3px $color, 3px -3px $color, -3px 3px $color;
    -moz-text-shadow: 4px 0 0 $color, -4px 0 0 $color, 0 4px 0 $color, 0 -4px 0 $color, 3px 3px $color, -3px -3px $color, 3px -3px $color, -3px 3px $color;
    -ms-text-shadow: 4px 0 0 $color, -4px 0 0 $color, 0 4px 0 $color, 0 -4px 0 $color, 3px 3px $color, -3px -3px $color, 3px -3px $color, -3px 3px $color;
    text-shadow: 4px 0 0 $color, -4px 0 0 $color, 0 4px 0 $color, 0 -4px 0 $color, 3px 3px $color, -3px -3px $color, 3px -3px $color, -3px 3px $color;
    */
    -webkit-text-shadow:
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color;
    -moz-text-shadow:
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color;
    -ms-text-shadow:
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color;
    text-shadow:
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color,
        0 0 8px $color, 0 0 8px $color, 0 0 8px $color, 0 0 8px $color;
}
@mixin text-border-5($color) {
    /*
    -webkit-text-stroke-width: 5px;
   -webkit-text-stroke-color: $color;

    -webkit-text-shadow: 5px 0 0 $color, -5px 0 0 $color, 0 5px 0 $color, 0 -5px 0 $color, 4px 4px $color, -4px -4px $color, 4px -4px $color, -4px 4px $color;
    -moz-text-shadow: 5px 0 0 $color, -5px 0 0 $color, 0 5px 0 $color, 0 -5px 0 $color, 4px 4px $color, -4px -4px $color, 4px -4px $color, -4px 4px $color;
    -ms-text-shadow: 5px 0 0 $color, -5px 0 0 $color, 0 5px 0 $color, 0 -5px 0 $color, 4px 4px $color, -4px -4px $color, 4px -4px $color, -4px 4px $color;
    text-shadow: 5px 0 0 $color, -5px 0 0 $color, 0 5px 0 $color, 0 -5px 0 $color, 4px 4px $color, -4px -4px $color, 4px -4px $color, -4px 4px $color;
    */
    -webkit-text-shadow:
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color;
    -moz-text-shadow:
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color;
    -ms-text-shadow:
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color;
    text-shadow:
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color,
        0 0 10px $color, 0 0 10px $color, 0 0 10px $color, 0 0 10px $color;
}

@mixin appearance($val){
	appearance: $val;
	-ms-appearance: $val;
	-moz-appearance: $val;
	-webkit-appearance: $val;
}
