
body {
    div {
        section {
            .bottom-buttons {
                min-height: 104px;
                position: fixed;
                left: 50%;
                bottom: 20px;
                width: 100%;
                transform: translate(-50%, 0);
                display: flex;
                flex-direction: column;
                flex-flow: column;
                justify-content: space-between;
                align-items: center;

                &.justify-bottom {
                    justify-content: flex-end;
                }

                button, img.bottom-logo {
                    display: block;
                    margin: 5px 0;
                    max-width: 110px;
                }

                .bottom-logo {
                    width: 160px;
                }

                button.fb-login {
                    width: 179px;
                    height: 47px;
                    background-color: #4267b2;
                    border-radius: 40px;

                    @media screen and (max-width: 374px) {
                        width: 138px;
                        height: 38px;
                    }

                    span {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: .8em;

                        svg {
                            margin-right: 10px;
                        }
                    }
                }

                button.loadin-button {
                    display: flex;
                    position: relative;
                    color: #000;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        display: block;
                        width: 100%;
                        height: 100%;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    & > svg {
                        -webkit-animation: fa-spin 2s infinite linear;
                        animation: fa-spin 2s infinite linear;
                        position: absolute;
                        color: #fff;
                        fill: #fff;
                    }
                }
            }
        }
    }

    .fa-spin{animation:fa-spin 2s infinite linear}
    .fa-pulse{animation:fa-spin 1s infinite steps(8)}
    @keyframes fa-spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}
}
