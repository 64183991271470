
body {
    div {
        section {
            .bottom-buttons-new {
                height: 62px;
                position: fixed;
                left: 0;
                bottom: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-flow: column;
                justify-content: center;
                align-items: center;

                .linkTyC{
                    color: #fff;
                    font-size: 8px;
                    position: absolute;
                    right: 70px;
                    @media screen and (min-height: 640px) {
                        font-size: 12px;
                    }
                    @media screen and (min-height: 780px) {
                        font-size: 1.1em;
                        font-size: 14px;
                    }
                }

                button, img.bottom-logo {
                    display: block;
                    margin: 0;
                }

                .bottom-logo {
                    width: 160px;
                }

                .next-button-new {
                    position: absolute;
                    right: 15px;
                    left: auto;
                    margin-top: -1px;
                }
            }
        }
    }
}
